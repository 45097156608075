import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../../../components/layout'

const NorthAll = ({data}) => {
    const links = ['/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-anantha-padmanabha-vratham-puja-online-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-annaprasana-pooja-or-first-rice-feeding-eating-ceremony-for-baby-boy-girl',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-ayudha-pooja-services-online-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-ayush-homam-services-for-baby-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-ayyappa-swamy-puja-services-online-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-baby-ear-piercing-karnavedha-ceremony-infant-hindu-boys-girls',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-bheema-ratha-shanthi-homam-or-pooja-70th-birthday',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-bhoomi-pooja-for-new-house-construction-office-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-chandi-homam-havan-at-home-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-chandi-parayanam-path-or-puja-online-for-durga-saptashati-yagnam',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-dasara-durga-or-vijayadashami-puja-at-home-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-special-deepawali-lakshmi-pooja-at-night-or-day-time-in-home',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-dhanvantari-homam-pooja-services-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-hindu-engagement-ceremony-for-north-south-indians-online',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-ganesh-ganapathi-homam-services-at-home-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-ganesh-ganapathi-puja-services-at-home-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-house-warming-ceremony-gruhapravesam-in-bangalore',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-kala-sarpa-dosha-nivaran-pooja-services-online-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-karthaveeryarjuna-homam-havan-services-online-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-shri-lord-krishna-janmashtami-puja-services-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-lakshmi-ganapathi-homam-services-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-lakshmi-narashmina-homam-havan-services-online-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-last-rites-or-antim-sanskar-in-bangalore',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-mahalakshmi-homam-havan-services-at-home-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-makar-sankranti-pongal-special-puja-services-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-mrityunjaya-homam-havan-puja-services-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-naamkaran-or-hindu-baby-naming-ceremony-for-new-born-child-online',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-mool-nakshatra-pooja-services-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-navagraha-homam-services-at-home-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-navagraha-shanti-puja-services-at-home-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-navarathri-devi-durga-special-puja-vrat-for-9-days-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-new-office-or-shop-opening-pooja-online-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/book-pandit-for-maa-saraswati-homam-puja-services-today-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-sathabhishekam-function-for-80th-birthday',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-satyanarayana-swamy-vratam-mahapooja-online-at-home-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-seemantham-ceremony-twins-babyshower-function-online-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-shastipoorthi-puja-marriage-celebrations-for-60th-kalyanam',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-maha-shiva-rudrabhishekam-puja-online-in-bangalore-india',
'/findonline/pandit-for-shradh-in-bangalore',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-sudarshana-homam-puja-at-home-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-swarna-gowri-vratham-pooja-at-home-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-tulsi-plant-vivah-pooja-at-home-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-upanayana-or-sacred-thread-ceremony-for-hindu-brahmins-sindhi-more',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-varalakshmi-pooja-or-simple-varamahalaxmi-vratham-at-home',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-vasthu-shanti-pooja-services-online-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-shri-vishwakarma-puja-services-online-in-bangalore-india',
'/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/hindu-wedding-priest-or-pandit-for-marriage-in-bangalore-india']
    return <Layout>
        <Helmet>
            <title>Purohits in Bangalore: Best Priests and Pujari Services in Bangalore</title>
            <meta name="description" content="Looking for Hindu Purohits in Bangalore for North and South Indian Pooja & Marriages? We provide best Priests and Pujari Services in Bangalore, India" />
        </Helmet>
        <div id="main" className="pandit-listing">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1 className="desktop">Purohits in Bangalore: Best Priests<br/>and Pujari Services in Bangalore</h1>
                        <h1 className="mobile">Purohits in Bangalore: Best Priests<br/>and Pujari Services in Bangalore</h1>
                    </header>
                    <p>Looking for Hindu Purohits in Bangalore for North and South Indian Pooja & Marriages? We provide best Priests and <a href='https://en.wikipedia.org/wiki/Purohit' className='no-highlight'>Pujari</a> Services in Bangalore, India.</p>
                </div>
            </section>
            <section id="two" className="spotlights">
                <div className="inner">
                    <div className="box alt">
                        <div className="grid-wrapper">
                            {data.allFile.nodes.map((value, index) => {
                                return <div className="col-4"><span className="image fit"><Link to={links[index]}><img src={value.childImageSharp.fluid.src} alt={value.name} title={value.name} /></Link><Link to={links[index]}><h4>{value.name}</h4></Link></span></div>
                            })}
                        </div>
                    </div>                
                </div>
            </section>
        </div>

    </Layout>
}

export const query = graphql`
{
  allFile(filter: {name: {in: ["Anantha Padmanabha Vratham Puja", "Annaprasanna", "Ayudha Puja", "Ayusha Homam", "Ayyappaswamy Puja", "Baby Ear Piercing Puja", "Bheema Ratha Shanti (70th Birthday)", "Bhoomi Puja", "Chandi Homam", "Chandi Parayanam", "Dasara Puja", "Deepawali Lakshmi Puja", "Dhanvantari Homam", "Engagement Ceremony", "Ganapati Homam", "Ganesh Puja", "Griha Pravesh", "Kaal Sarpa Dosh", "Karthaveeryarjuna Homam", "Krishna Janmashtami Puja", "Lakshmi Ganapathi Homam", "Lakshmi Narasimha Homam", "Last-Rites or Antim Sanskar", "Maha Lakshmi Homam", "Makar Sankranti Puja", "Mrityunjaya Homam", "Naamakaran", "Nakshatra Shanti Puja", "Navagraha Homam", "Navagraha Shanti Puja", "Navaratri Puja", "Office Opening Puja", "Saraswathi Homam", "Sathabhishekam (80th Birthday)", "Satyanarayana Puja", "Seemantham", "Shashtipoorthi (60th Birthday)", "Shiva Rudrabhishekam", "Shraddha or Death Anniversary", "Sudarshana Homam", "Swarna Gowri Vratha", "Tulasi Vivaah Puja", "Upanayana", "Varalakshmi Puja", "Vasthu Shanti Puja", "Vishwakarma Puja", "Wedding Puja"]}}, sort: {fields: name}) {
    nodes {
      name
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}`

export default NorthAll